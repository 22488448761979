<template>
    <FiltroTesserati
        @applyFilter="this.applyFilterTesserato($event)"
        @clearFilter="this.resetfiltersTesserato"
    />
    <GrigliaTesserati
        :listaTesserati ="this.ListaUtentiAsync"
        @getTesserati="this.getData"
    />
</template>

<script>
import AuthService from "@/services/auth.service.js";
import FiltroTesserati from "@/components/Filters/FiltroTesseratiB2B.vue";
import GrigliaTesserati from "@/components/GrigliaTesseratiB2B.vue";

export default {
	name: "ListaUtenti",
    data() {
        return {
            ListaUtenti: [],
            ListaUtentiAsync: [],
            ListaUtentiAsyncOriginale: []
        };
    },
    watch:{
        ListaUtenti: function(val){
            this.ListaUtentiAsync = val;
        }
    },
    components:{
        FiltroTesserati,
        GrigliaTesserati
    },
    methods: {
        getData: function () {
            let token = this.$store.getters.getToken;
            AuthService.getUtentiB2C( token ).then(data => {
                if (!data) { 
                    this.$router.push("/"); 
                } 
                else { 
                    this.ListaUtenti = data; 
                    this.ListaUtentiOriginale = this.ListaUtenti;
                }
            });
        },
        intToBool: function(int){
            if (int === -1){
                return false;
            }else{
                return true;
            }
        },
        applyFilterTesserato: function(searchKey){
            this.ListaUtenti = this.ListaUtentiOriginale;
            this.ListaUtenti = this.ListaUtenti.filter( item =>{
                let cond = true;
                if(searchKey ){
                    let lowerNome = item.NomeTesserato.toLowerCase();
                    let lowerCognome = item.CognomeTesserato.toLowerCase();

                    if(searchKey.NOME != null && searchKey.NOME != ""){
                        let lowerKeyNome = searchKey.NOME.toLowerCase();
                        let vn = lowerNome.search(lowerKeyNome); 
                        let vc = lowerCognome.search(lowerKeyNome);
                        cond = cond && (this.intToBool(vn) || this.intToBool(vc));
                    }
                    if(searchKey.ANNO != null && searchKey.ANNO != ""){
                        let year = new Date(item.DataNascitaTesserato).getFullYear();
                        cond = cond && (year == searchKey.ANNO);
                    }
                    if(searchKey.CATEGORIA != null && searchKey.CATEGORIA != ""){
                        cond = cond && (searchKey.CATEGORIA == item.CategoriaTesserato);
                    }
                    if(searchKey.STATO != null && searchKey.STATO != ""){
                        if(searchKey.STATO == "Attivo"){
                            cond = cond && (item.Abilitato == true);
                        }else if(searchKey.STATO == "Tutti"){
                            cond = cond && true;
                        }else{
                            cond = cond && (item.Abilitato == false);
                        }
                    }
                }else{
                    this.ListaUtenti = this.ListaUtentiOriginale;
                }
                return cond;
            });
        },
        resetfiltersTesserato: function(){
            this.ListaUtenti = this.ListaUtentiOriginale;
        }
    },
    created: function () {
        this.getData();
    },
}
</script>
