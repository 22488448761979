<template>
    <div class="Box">
        <h3 class="UserDataTableIndex"><span>Gestione tesserati</span><a class="FilterPanelOpenButton" onClick="orderhistoryfilter()"><img src="/assets/images/FilterPanelButtonIcon.png" srcset="/assets/images/FilterPanelButtonIconRetina.png 2x" alt="Filtra elenco" /><i>Filtri</i></a></h3>
        <div class="FilterPanel DefaultPage">
            <form class="Form">
                <fieldset>
                    <legend>Filtra elenco per:</legend>
                    <div class="FormRow">
                        <div class="FormBox20">
                            <label>Nome tesserato</label>
                            <input type="text" v-model="this.nomeTesserato">
                        </div>
                        <div class="FormBox20">
                            <label>Anno di nascita</label>
                            <input type="text" v-model="this.annoDiNascita">
                        </div>
                        <div class="FormBox20">
                            <label>Categoria</label>
                            <select class="CustomSelect" v-model="this.categoriaTesserato">
                                <option value="">Scegli</option>
                                <option v-for="categoria in this.listaCategorie" :key="categoria?.Codice" :value="categoria?.Codice">{{categoria?.Descrizione}}</option>
                            </select>
                        </div>
                        <div class="FormBox20">
                            <label>Stato tesserato</label>
                            <select class="CustomSelect" v-model="this.statoTesserato">
                                <option value="Tutti">Tutti</option>
                                <option value="Attivo">Attivo</option>
                                <option value="NonAttivo">Non attivo</option>
                            </select>
                        </div>
                        <div class="FormBox20">
                            <label>&nbsp;</label>
                            <div class="Button Small FilterButton">
                                <input type="button" value="Filtra" @click="this.applyFilters()">
                                <input type="button" value="Rimuovi filtri" @click="this.clearFilters">
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    name:"FiltroTesseratiB2B",
    data(){
        return {
            nomeTesserato: "",
            cognomeTesserato: "",
            categoriaTesserato: "",
            annoDiNascita: "",
            statoTesserato: "Attivo"
        }
    },
    computed:{
        listaCategorie:function(){
			return this.$store.getters.getCategorie;
		},
        listaSocieta: function(){
			return this.$store.getters.getSocieta;
		}
    },
    watch:{
        nomeTesserato: function(newValue){
            if(newValue = ""){
                this.$emit("clearFilter");
            }else{
                this.cercaPerNomeCognome();
            }
        }
    },
    emits:["clearFilter","applyFilter"],
    methods:{
        clearFilters: function(){
            this.nomeTesserato= "";
            this.cognomeTesserato= "";
            this.categoriaTesserato="";
            this.annoDiNascita="";
            this.statoTesserato="Attivo";
            this.$emit("clearFilter");
        },
        cercaPerNomeCognome: function(){
            let n = this.nomeTesserato;
            this.applyFilters(n);
        },
        applyFilters: function(nome = null,anno = null,categoria = null,stato = null){
            let payload = {
                NOME: nome != null ? nome : this.nomeTesserato ,
                ANNO: anno != null ?  anno : this.annoDiNascita,
                CATEGORIA: categoria != null ? categoria : this.categoriaTesserato,
                STATO: stato != null ? stato : this.statoTesserato
            };
            this.$emit("applyFilter", payload);
        },
    }

}
</script>