<template>
		<section v-if="CarouselProducts.length > 0">
            <div class="Container">
                <div class="Box">
                    <div class="Bookmark"><span>{{Titolo}}</span></div>
                </div>
                <div class="Box">
                    <div :id="Categoria" class="ClassicIkarus Cat1 owl-carousel owl-theme">
                    <!-- Inizio slide -->
                        <ProductCard
                        v-for="CarouselProduct in CarouselProducts" 
                        :key="CarouselProduct.CodiceArticolo"
                        :Prodotto="CarouselProduct"
                        :Template="'Carousel'"/>
                    <!-- Fine slide -->
                    </div>
                </div>
            </div>
        </section>
</template>
<script>
import nextTick from 'vue';
import ProductCard from "@/components/ProductCard.vue";
import ProductsService from "@/services/products.service.js";
import {
    modelMixin,
    risorseMixin
} from "@/mixin.js";

export default {
    name: "FeaturedProducts",
    props: [
        'Categoria',
        'Titolo',
        'Nome',
        'CodSocieta'
    ],
    data() {
        return {
            CarouselProducts: [],
        }
    },
    components: {
        ProductCard
    },
    methods: {
        getData: function () {
            let token = this.$store.getters.getToken;
            let shop = this.$store.getters.getShop;
            ProductsService.getArticoli(token, shop, this.Categoria,this.CodSocieta).then(
                data => {
                    if (!data) {
                        this.$router.push("/");
                    } else {
                        this.CarouselProducts = data;
                    }
                }
            )
        }
    },
    mixins: [modelMixin, risorseMixin],
    created: function () {
        this.getData();       
    },

    watch: {
    CarouselProducts: function (newValue, oldValue) {
        let idcarousel = "#" + this.Categoria;
        $(document).ready(function() {
                $(idcarousel).owlCarousel({
                    margin:24,
                    autoplay:false,
                    nav:false,
                    responsive:{
                        0:{
                            items:1,
                        },
                        600:{
                            items:2,
                        },
                        1000:{
                            items:3,
                        },
                        1300:{
                            nav:true,
                            items:3,
                        },
                        1600:{
                            nav:true,
                            items:4,
                            margin:48,
                        }
                    }
                });
            });
    },

    }
}



</script>

