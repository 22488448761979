<template>
    <div class="Box">
        <div class="EnableGroup">
            <div class="EnableGroupTitle">Elenco tesserati</div>
            <div class="ProfileMemberTable EnableTable">
                <table class="PMMainTable" width="100%" border="0" cellspacing="0" cellpadding="0" >
                    <tbody>
                        <tr>
                            <th scope="col" class="PMTName">Nome</th>
                            <th scope="col" class="PMTSurName">Cognome</th>
                            <th scope="col" class="PMTBirthDate">Data di nascita</th>
                            <th scope="col" class="PMTCategory">Categoria</th>
                            <th scope="col" class="PMTParents">Dati Genitore</th>
                            <th scope="col" class="PMTModify">&nbsp;</th>
                        </tr>
                        <RowTesseratoB2B 
                            v-for="tesserato in this.listaTesserati" :key="tesserato.CodiceTesserato"
                            :Tesserato="tesserato"
                            @getTesserati="this.propagateEv()"
                        />
                    </tbody>
                </table>
            </div>	
        </div>
    </div>
    <div class="WhiteSpace">&nbsp;</div>
    <div class="WhiteSpace">&nbsp;</div>
    <div class="WhiteSpace">&nbsp;</div>
</template>
<script>
import RowTesseratoB2B from "@/components/RowTesseratiB2B.vue";

export default {
    name:"GrigliaTesseratiB2B",
    components:{
        RowTesseratoB2B
    },
    props:{
        listaTesserati: Array
    },
    emits:["getTesserati"],
    methods:{
        propagateEv:function(){
            this.$emit("getTesserati");
        }
    }
}
</script>