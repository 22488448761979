<template>
<div class="Box">
    <div class="HeadingGroup">
        <h1 v-html="risorse('KIT')"></h1>
        <div class="TxtEditor">
            <p v-html="risorse('DescrizioneProdottiEvidenza')"></p>
        </div>
    </div>
    <div class="HighlightProducts">
        <ProductCard v-for="FeaturedProduct in FeaturedProducts" 
            :key="FeaturedProduct.CodiceArticolo" 
            :Prodotto="FeaturedProduct" 
            :Template="'FeaturedProduct'"
            :CodiceSocieta="this.CodSocieta"
        />
    </div>
</div>
</template>
<script>
import ProductsService from "@/services/products.service.js";
import { modelMixin, risorseMixin, userMixin, valutaMixin } from "@/mixin.js";
import ProductCard from "./ProductCard.vue";

export default {
    name: "FeaturedProducts",
    data() {
        return {
            FeaturedProducts: [],
        };
    },
    props:{
        CodSocieta: String
    },
    methods: {
        getData: function () {
			this.$store.commit('setLoader', true);
            let token = this.$store.getters.getToken;
            let shop = this.$store.getters.getShop;
            ProductsService.getFeaturedProducts(token, shop, this.CodSocieta).then(data => {
                if (!data) {
                    this.$router.push("/");
                    this.$store.commit('setLoader', false);
                }
                else {
                    this.FeaturedProducts = data;
                    this.$store.commit('setLoader', false);
                }
            });
        }
    },
    watch:{
        CodSocieta: function(newValue){
            if(newValue != null && newValue.length > 0){
                this.getData();
            }
        }
    },
    mixins: [modelMixin, risorseMixin, userMixin, valutaMixin],
    mounted: function () {
        if(this.CodSocieta != null && this.CodSocieta.length > 0){
            this.getData();
        }
    },
    components: { ProductCard }
}
</script>

