<template>
    <div v-if="this.isB2B" class="LayoutHolder" id="PageTop">
		<section v-if="this.ready" class="WhiteSmoke">
			<div class="Container">
				<div class="Box WelcomeBox">
					<div class="Welcome">
						<div class="WelcomeText"><span>Benvenuto, </span><span>{{ NomeSocieta }}</span></div>
					</div>
				</div>
				<lista-utenti/>
				<featured-products
					:CodSocieta="this.CodiceSocieta"
				/>
				<products-carousel :Categoria="'ACG'" :Titolo="'Utilità'" :CodSocieta="this.CodiceSocieta"></products-carousel>
			</div>
		</section>
    </div>
	<div v-else class="LayoutHolder" id="PageTop">
		<section v-if="this.ready" class="WhiteSmoke">
			<div class="Container">
				<div class="Box WelcomeBox">
					<div class="Welcome">
						<div class="WelcomeText"><span>Ciao, </span>
							<router-link :to="{ name: 'Profile' }">{{NomeUtente}} {{CognomeUtente}} </router-link>
							<div v-if="this.ListaSocietaTesserati != null && this.ListaSocietaTesserati.length > 1" class="WelcomeSelect">
								<form>
									<div class="FormRow">
										<div class="FormBoxWide">
											<select class="CustomSelect" v-model="this.selectedSocieta" @change="this.changeValue($event)">
												<option v-for="societa in this.ListaSocietaTesserati" :key="societa.CodSocieta" :value="societa.CodSocieta">{{societa.DescrSocieta}}</option>
											</select>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<featured-products v-if="this.tesseratiLenght > 0" 
					:CodSocieta="this.CodiceSocieta"
				/>
				<div v-if="this.carouselReady">
					<products-carousel 
						v-for="categoria in this.listaCategorie" 
						:key="categoria.CodiceCategoria"
						:Categoria="categoria.CodiceCategoria" 
						:Titolo="categoria.DescrIT"
					/>
				</div>
				<div v-if="this.tesseratiLenght == 0" class="HeadingGroup">
					<h1>OOPS</h1>
					<div class="TxtEditor">
						<p> Sembra che non hai tesserati, puoi aggiungerli da qui:</p>
						<router-link :to="{'name': 'GestioneTesserati'}">Gestione Tesserati</router-link>
					</div>
				</div>
			</div>
		</section>
    </div>   
</template>

<script>
import FeaturedProducts from '@/components/FeaturedProducts.vue';
import ListaUtenti from '@/components/ListaUtenti.vue';
import {risorseMixin, userMixin} from "@/mixin.js";
import ProductsCarousel from '@/components/ProductsCarousel.vue';

export default {
    name: 'Home',
    components: {
    FeaturedProducts,
    ProductsCarousel,
	ListaUtenti	
	},
    data() {
        return {
			CodiceSocieta: "",
			ready: false,
			selectedSocieta: "",
			tesseratoUnico: null,
			noTesserati: false,

			carouselReady: false,
			listaCategorie: []
        };
    },  
    mixins:[risorseMixin, userMixin],
	mounted:function(){
		if(!this.isB2B){
			var tesserati = this.$store.getters.getAnagraficaUtente?.Tesserati;
			if(tesserati != null){
				let idx = tesserati.findIndex(t =>{
					return t.Attivo == 1;
				});
				if(idx != -1){
					this.CodiceSocieta = this.$store.getters.getAnagraficaUtente?.Tesserati[idx]?.CodiceSocieta;
					this.tesseratoUnico = this.$store.getters.getAnagraficaUtente?.Tesserati[idx];
					let tmpSoc = this.$store.getters.getSelectedSociety;
					if(tmpSoc == null){
						this.selectedSocieta = this.CodiceSocieta;
						this.$store.commit('setSelectedSociety',this.selectedSocieta);
					}else{
						this.selectedSocieta = this.$store.getters.getSelectedSociety;
						this.CodiceSocieta = this.selectedSocieta;
					}
				}
				/*this.$store.dispatch({
					type: 'GetCategorieFromApi',
					token: this.$store.getters.getToken,
					shop: 'b2c'
				}).then((data) =>{
					this.listaCategorie = this.$store.getters.getCategorieShop;
					this.carouselReady = true;
				});*/
			}else{
				this.noTesserati = true;
			}
		}else{
			this.CodiceSocieta = this.$store.getters.getAzienda?.Anagrafica?.CodiceCliente;
		}
		this.ready= true;
	},
	computed:{
		DescrSocieta: function(){
			let societa = this.ListaSocietaTesserati;
			if(societa != null){
				return societa[societa.findIndex( s => {
					return s.CodSocieta == this.CodiceSocieta;
				})].DescrSocieta;
			}else{
				return "";
			}
		}
	},
	methods:{
		changeValue: function(event){
			this.CodiceSocieta = this.selectedSocieta;
			this.$store.commit('setSelectedSociety',this.selectedSocieta);
		},
		GetCart(){
			this.$store.dispatch({
				type: 'setLoader',
				value: true
			});
			this.$store.dispatch({
				type: 'getCarrello',
				token: this.$store.getters.getToken,
				shop: this.$store.getters.getShop
			}).then((data) =>{
				this.$store.dispatch({
					type: 'setLoader',
					value: false
				});
			});
		}
	},
	beforeRouteLeave(to,from,next){
		this.carouselReady = false;
		next();
	}
}
</script>

