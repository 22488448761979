<template>
    <tr >
        <td colspan="6">
            <table class="PMSubTable" width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td class="PMTName">{{this.Tesserato.NomeTesserato}}</td>
                        <td class="PMTSurName">{{this.Tesserato.CognomeTesserato}}</td>
                        <td class="PMTBirthDate">{{this.formatDateNoOre(this.Tesserato.DataNascitaTesserato)}}</td>
                        <td class="PMTCategory">{{this.Categoria}}</td>
                        <td v-if="this.haGenitori" class="PMTParents" @click="this.openRow"><div class="TableToggleButton"><span>Visualizza</span></div></td>
                        <td v-else class="PMTParents"><div><span>/</span></div></td>
                        <td class="PMTModify">
                            <div v-if="this.abilitato" class="Button Stroked Small" @click="this.changeStateTesserato"><a>Disabilita</a></div>
                            <div v-else class="Button CustomColor2 Small" @click="this.changeStateTesserato"><a>Abilita</a></div>
                        </td>
                    </tr>
                    <tr class="MemberParents" ref="riga">
                        <td colspan="6">
                            <table class="PMSubSubTable" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td colspan="4"><h4>Dati Genitore</h4></td>
                                    </tr>
                                    <tr>
                                        <td><i>Nome</i><span>{{this.Tesserato.NomeGenitore}}</span></td>
                                        <td><i>Cognome</i><span>{{this.Tesserato.CognomeGenitore}}</span></td>
                                        <td><i>Data di Nascita</i><span>{{this.dtNaschitaGenitore}}</span></td>
                                        <td><i>Telefono</i><span>{{this.Tesserato.TelefonoGenitore}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>
<script>
import {apiMixin,dateMixin} from "@/mixin.js";

export default {
    name:"RowTesseratiB2B",
    mixins:[apiMixin,dateMixin],
    props:{
        Tesserato:Object
    },
    emits:["getTesserati"],
    mounted: function(){
        $(".MemberParents").hide();
        $(".TableToggleButton").addClass('Open');
    },
    computed:{
        Categoria: function(){
            let categorie = this.$store.getters.getCategorie;
            if(categorie != null){
                return categorie[categorie.findIndex( c =>{
                    return c.Codice == this.Tesserato.CategoriaTesserato;
                })]?.Descrizione;
            }
            return " - ";
        },
        haGenitori: function(){
            return this.Tesserato.NomeGenitore != null;
        },
        abilitato: function(){
            return this.Tesserato.Abilitato;
        },
        dtNaschitaGenitore: function(){
            return this.Tesserato.DataNascitaGenitore != null ? this.formatDateNoOre(this.Tesserato.DataNascitaGenitore) : '-';
        }
    },
    methods:{
        openRow: function(){
            $(this.$refs.riga).parent().parent().parent().find(".MemberParents").slideToggle('fast');
            $(this.$refs.riga).toggleClass('Open').toggleClass('Close');
        },
        changeStateTesserato: function(){
            this.$store.dispatch({
                type: 'setLoader',
                value: true
            });
            let action = "";
            if(this.abilitato){
                action = "DisabilitaTesserto";
            }else{
                action = "AbilitaTesserato";
            }
            this.doApiCall("post","b2b/Utenti/"+action, this.$store.getters.getToken,null,{username: this.Tesserato.Username, key: this.Tesserato.k2Riga}).then(
                (data) => {
                    this.$emit("getTesserati");
                    this.$store.dispatch({
                        type: 'setLoader',
                        value: false
                    });
                }
            );
        }
    }
}
</script>